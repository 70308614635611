import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
  Box,
  Button,
  ButtonGroup,
  Typography,
  IconButton
} from '@mui/material';
import { confirmAlert } from 'react-confirm-alert';
import { UserGroupSearch } from '../../Components/Widgets/UserSearch';
import ToastMessage from '../../Components/Widgets/ToastMessage';
import './Application.scss';
import DataStore from '../../Stores/DataStore';
import { ACTIVE_ROLES, ACTIVE_ROLES_DISPLAY_NAME } from '../../Lib/CONSTANTS';
import useUserPermissions from '../../hooks/useUserPermissions';
import { useUserQuery } from '../../hooks/useUser';
import { Icon } from '../../Components/Shared/Icon';
import { Button as NCButton, Table } from '@nucleos/core-ui';
import Data from '../../Middleware/Data';
import { useQuery } from 'react-query';
import NoResultFoundSVG from '../../Assets/graphics/table_no_data_found.svg';
import { Link } from 'react-router-dom';
import { GROUP_PATH, USER_PATH } from '../../constants/paths';

const ApplicationACLUsersTable = ({ applicationUid, onRemoveUser }) => {
  const [searchTerm/*, setSearchTerm */] = useState('');
  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const applicationACLUsersQuery = useQuery(
    [
      'APPLICATION_ACL_USERS',
      applicationUid,
      searchTerm,
      page,
      recordsPerPage
    ],
    () =>
      Data.getApplicationACLUsers({
        applicationUid,
        offset: (page - 1) * recordsPerPage,
        limit: recordsPerPage,
        query: {
          search: searchTerm
        }
      }),
    {
      select: (data) => {
        return {
          ...data.users,
          rows: data.users.rows || []
        };
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true
    }
  );

  const applicationACLUsersData = applicationACLUsersQuery.data;

  const handleRemoveUser = useCallback(async (uid) => {
    await onRemoveUser(uid);

    applicationACLUsersQuery.refetch();
  }, [onRemoveUser, applicationACLUsersQuery]);

  const columns = useMemo(() => {
    const columns = [
      {
        title: 'Name',
        render: (item) => (
          <Link to={`${USER_PATH}/${item.uid}`}>
            <Typography variant='link'>{`${item.firstName} ${item.lastName}`}</Typography>
          </Link>
        )
      },
      {
        title: `${window.nucleosConfig.usernameLabel || 'Username'}`,
        render: (item) => (
          <Typography>{`${item.username}`}</Typography>
        )
      },
      {
        title: 'Role',
        render: (item) => (
          <Typography>{`${ACTIVE_ROLES_DISPLAY_NAME[item.Role.name]}`}</Typography>
        )
      },
      {
        title: 'Action',
        enableSort: false,
        render: (item) => (
          <IconButton size='small' onClick={() => handleRemoveUser(item.uid)}>
            <Icon icon="DeleteOutlined" color="error" />
          </IconButton>
        )
      }
    ];

    return columns;
  }, [handleRemoveUser]);

  useEffect(() => {
    if (applicationACLUsersData && (Math.ceil(applicationACLUsersData.count / recordsPerPage) < page)) {
      setPage(1);
    }
  }, [applicationACLUsersData, page, recordsPerPage]);

  return (
    <>
      <div className="nucleos-core">
        <Table
          columns={columns}
          noDataMessage={
            <div className="flex flex-col items-center justify-center">
              <img src={NoResultFoundSVG} alt="No result found" />
              <Typography className="mb-2" variant="h3">
                Sorry! No results found.
              </Typography>
              {searchTerm && (
                <Typography className="mb-2">
                  Sorry, there are no results for this search.
                </Typography>
              )}
            </div>
          }
          loading={applicationACLUsersQuery.status !== 'success'}
          rowsData={applicationACLUsersData ? applicationACLUsersData.rows : []}
          pagination
          totalRecords={applicationACLUsersData ? applicationACLUsersData.count : 0}
          recordsPerPage={recordsPerPage || 10}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={setPage}
        />
      </div>
    </>
  );
};

const ApplicationACLGroupsTable = ({ applicationUid, onRemoveGroup }) => {
  const [searchTerm/*, setSearchTerm */] = useState('');
  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const applicationACLGroupsQuery = useQuery(
    [
      'APPLICATION_ACL_USERS',
      applicationUid,
      searchTerm,
      page,
      recordsPerPage
    ],
    () =>
      Data.getApplicationACLGroups({
        applicationUid,
        offset: (page - 1) * recordsPerPage,
        limit: recordsPerPage,
        query: {
          search: searchTerm
        }
      }),
    {
      select: (data) => {
        return {
          ...data.groups,
          rows: data.groups.rows || []
        };
      },
      refetchOnWindowFocus: false,
      refetchOnMount: true
    }
  );

  const applicationACLGroupsData = applicationACLGroupsQuery.data;

  const handleRemoveGroup = useCallback(async (uid) => {
    await onRemoveGroup(uid);

    applicationACLGroupsQuery.refetch();
  }, [onRemoveGroup, applicationACLGroupsQuery]);

  const columns = useMemo(() => {
    const columns = [
      {
        title: 'Name',
        render: (item) => (
          <Link to={`${GROUP_PATH}/${item.uid}`}>
            <Typography variant='link'>{`${item.name}`}</Typography>
          </Link>
        )
      },
      {
        title: 'Description',
        render: (item) => (
          <Typography>{`${item.description}`}</Typography>
        )
      },
      {
        title: 'Action',
        enableSort: false,
        render: (item) => (
          <IconButton size='small' onClick={() => handleRemoveGroup(item.uid)}>
            <Icon icon="DeleteOutlined" color="error" />
          </IconButton>
        )
      }
    ];

    return columns;
  }, [handleRemoveGroup]);

  useEffect(() => {
    if (applicationACLGroupsData && (Math.ceil(applicationACLGroupsData.count / recordsPerPage) < page)) {
      setPage(1);
    }
  }, [applicationACLGroupsData, page, recordsPerPage]);

  return (
    <>
      <div className="nucleos-core">
        <Table
          columns={columns}
          noDataMessage={
            <div className="flex flex-col items-center justify-center">
              <img src={NoResultFoundSVG} alt="No result found" />
              <Typography className="mb-2" variant="h3">
                Sorry! No results found.
              </Typography>
              {searchTerm && (
                <Typography className="mb-2">
                  Sorry, there are no results for this search.
                </Typography>
              )}
            </div>
          }
          loading={applicationACLGroupsQuery.status !== 'success'}
          rowsData={applicationACLGroupsData ? applicationACLGroupsData.rows : []}
          pagination
          totalRecords={applicationACLGroupsData ? applicationACLGroupsData.count : 0}
          recordsPerPage={recordsPerPage || 10}
          onRecordsPerPageChange={(rowsPP) => {
            setRecordsPerPage(rowsPP);
            setPage(1);
          }}
          page={page}
          onPageChange={setPage}
        />
      </div>
    </>
  );
};

const TAB_KEYS = {
  Users: 'users',
  Groups: 'groups'
};

const ApplicationUsers = () => {
  const theme = useTheme();
  const userProfile = useUserQuery();
  const [currentAction, setCurrentAction] = useState(null);
  const userPermissions = useUserPermissions();
  const {
    appDetail,
    addAppUser,
    addAppGroup,
    addAppItemError,
    addAppItemSuccess,
    setGranular
  } = DataStore;
  const { uid, granular, active } = appDetail;

  const [granularTab, setGranularTab] = useState(TAB_KEYS.Users);

  function handleSelectAccessType (event) {
    const { name } = event.target;
    const isGranular = name === 'some';
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <p>
              {isGranular
                ? (
                  'With this change only the selected learners will be able to access the course. Are you sure you want to make this switch?'
                )
                : (
                  'With this change you are granting access to all the learners to this course. Are you sure you want to make this switch?'
                )}
            </p>
            <div className="react-confirm-alert-button-group">
              <NCButton style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }} onClick={onClose}>No</NCButton>
              <NCButton
                style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                onClick={() => {
                  setGranular(uid, isGranular);
                  onClose();
                  renderOnboardingInfoAlert();
                }}
              >
                Yes
              </NCButton>
            </div>
          </div>
        );
      },
      overlayClassName: 'app-confirmation'
    });
  }

  const renderOnboardingInfoAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <p>The onboarding of the user(s) can take up to 1-2 hours. Thank you for your patience.</p>
            <div className="react-confirm-alert-button-group">
              <NCButton style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }} onClick={onClose}>OK</NCButton>
            </div>
          </div>
        );
      },
      overlayClassName: 'app-confirmation'
    });
  };

  async function handleSelectUser (user) {
    const { uid: userId } = user;
    setCurrentAction('add');
    renderOnboardingInfoAlert();

    setGranularTab('');
    await addAppUser(uid, userId, true, user);
    setGranularTab(TAB_KEYS.Users);
  }

  async function handleSelectGroup (group) {
    const { uid: groupId } = group;
    setCurrentAction('add');
    renderOnboardingInfoAlert();

    setGranularTab('');
    await addAppGroup(uid, groupId, true, group);
    setGranularTab(TAB_KEYS.Groups);
  }

  async function handleRemoveUserAccess (userId) {
    setCurrentAction('remove');
    return addAppUser(uid, userId, false);
  }

  async function handleRemoveGroupAccess (groupId) {
    setCurrentAction('remove');
    return addAppGroup(uid, groupId, false);
  }

  function handleSnackBarClose (status) {
    const { setAddAppItemSuccess, setAddAppItemError } = DataStore;
    if (status === 'success') {
      setAddAppItemSuccess(null);
    } else {
      setAddAppItemError(null);
    }
    setCurrentAction(null);
  }

  const isFormDisabled =
    userProfile.status === 'success'
      ? userProfile.data.Role.name === ACTIVE_ROLES.EXTERNAL_PARTNER
      : true;

  const isCourseUserAccessActionDisabled = window.nucleosConfig.disableCourseUserAccessActions;

  return (
    <React.Fragment>
      <div className='app-detail__info-panel'>
        <RadioGroup
          onChange={handleSelectAccessType}
          value={granular ? 'some' : 'all'}

        >
          <FormControlLabel
            disabled={!active || isFormDisabled || isCourseUserAccessActionDisabled}
            value='all'
            control={<Radio name='all' color='primary' />}
            label='All users can access this content'
            labelPlacement='end'
          />
          <FormControlLabel
            disabled={!active || isFormDisabled || isCourseUserAccessActionDisabled}
            value='some'
            control={<Radio name='some' color='primary' />}
            label='Only the following specified users and groups can access this content:'
            labelPlacement='end'
          />
        </RadioGroup>
        {
          granular && (
            <React.Fragment>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                {
                  active && userPermissions.canAddRemoveUserInApp()
                    ? (
                      <UserGroupSearch
                        disabled={isCourseUserAccessActionDisabled}
                        onSelectUser={handleSelectUser}
                        onSelectGroup={handleSelectGroup}
                      />
                    )
                    : null
                }

                <Box>
                  <ButtonGroup size='small' color='secondary' variant='outlined'>
                    <Button
                      onClick={() => setGranularTab(TAB_KEYS.Users)}
                      sx={(theme) => ({
                        backgroundColor: granularTab === TAB_KEYS.Users ? `${theme.palette.secondary.main}05` : theme.palette.white.main,
                        color: theme.palette.getContrastText(
                          granularTab === TAB_KEYS.Users ? theme.palette.secondary.light : theme.palette.white.main
                        ),
                        px: 3
                      })}
                      startIcon={
                        granularTab === TAB_KEYS.Users
                          ? (
                            <Icon icon='Check' color='success' />
                          )
                          : null
                      }
                    >
                      All Users
                    </Button>
                    <Button
                      onClick={() => setGranularTab(TAB_KEYS.Groups)}
                      sx={(theme) => ({
                        backgroundColor: granularTab === TAB_KEYS.Groups ? `${theme.palette.secondary.main}05` : theme.palette.white.main,
                        color: theme.palette.getContrastText(
                          granularTab === TAB_KEYS.Groups ? theme.palette.secondary.light : theme.palette.white.main
                        ),
                        px: 3
                      })}
                      startIcon={
                        granularTab === TAB_KEYS.Groups
                          ? (
                            <Icon icon='Check' color='success' />
                          )
                          : null
                      }
                    >
                      All Groups
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>

              <Box className="tables-container" sx={{ mt: 2 }}>
                {
                  granularTab === TAB_KEYS.Users
                    ? (
                      <ApplicationACLUsersTable applicationUid={uid} onRemoveUser={handleRemoveUserAccess} />
                    )
                    : null
                }

                {
                  granularTab === TAB_KEYS.Groups
                    ? (
                      <ApplicationACLGroupsTable applicationUid={uid} onRemoveGroup={handleRemoveGroupAccess} />
                    )
                    : null
                }
              </Box>
            </React.Fragment>
          )
        }
      </div>

      <ToastMessage
        autoHideDuration={10000}
        message={'Item was successfully added to the access list.'}
        onClose={() => handleSnackBarClose('success')}
        open={!!addAppItemSuccess && currentAction === 'add'}
        type='success'
      />
      <ToastMessage
        autoHideDuration={10000}
        message={'Item could not be added. Please try again in a moment.'}
        onClose={() => handleSnackBarClose('error')}
        open={!!addAppItemError && currentAction === 'add'}
        type='error'
      />
      <ToastMessage
        autoHideDuration={10000}
        message={'Item has been removed from the access list.'}
        onClose={() => handleSnackBarClose('success')}
        open={!!addAppItemSuccess && currentAction === 'remove'}
        type='success'
      />
      <ToastMessage
        autoHideDuration={10000}
        message={'Item could not be removed. Please try again in a moment.'}
        onClose={() => handleSnackBarClose('error')}
        open={!!addAppItemError && currentAction === 'remove'}
        type='error'
      />
    </React.Fragment>
  );
};

export default observer(ApplicationUsers);
